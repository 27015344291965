import { useIntersectionObserver } from "@vueuse/core";
import { ref, toValue } from 'vue';

export function useStickyScrollProgress (container, sticky, isActive) {

  const progress = ref(0);

  const setProgress = () => {
    if (!toValue(container) || !toValue(sticky)) return;

    const scrollDistance = toValue(container).clientHeight - toValue(sticky).clientHeight;
    const scrollY = Math.min(scrollDistance, Math.max(0, -toValue(container).getBoundingClientRect().top));
    progress.value = scrollY / scrollDistance;
  };

  useIntersectionObserver(
    container,
    (entries, _) => {
      if (!toValue(isActive)) return;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', setProgress);
        } else {
          window.removeEventListener('scroll', setProgress);
        }
      });
    },
  );

  return progress;
}
